.custom-table .ant-table-cell {
    font-size: 17px; /* 修改单元格的字体大小 */
  }
  .custom-table th.ant-table-cell {
    font-size: 18px; /* 修改单元格的字体大小 */
  }
/* 
  .custom-table .ant-table {
    border: 2px solid #eff0f1;  
  }
  
  .custom-table .ant-table-thead > tr > th,
  .custom-table .ant-table-tbody > tr > td {
    border: 1px solid #e7e9eb; 
  } */
.custom-table .ant-table-tbody > tr > td {
    position: relative;
    padding: 12px 12px;
    overflow-wrap: break-word;
}